









import {
    Component,
    Vue
} from 'vue-property-decorator';
 

import {
    Auth
} from '@/store/auth'
import {
    Core
} from '@/store/core'
import {
    User
} from '@/store/user'

@Component({
    components: {
    
    },
})
export default class Home extends Vue {
    private dashboard: any = null
    private user: any = {}
    private response: boolean = false
    $vs: any

    public async created() {
        this.user = await User.getUser();
        this.response = true
    }

}
